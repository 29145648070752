define("admin/controllers/main/super/suspicious-accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    client: Ember.inject.service(),
    page: 1,
    totalPages: Ember.computed('model.pagination.total_pages', function () {
      var totalPages = this.get('model.pagination.total_pages');
      return totalPages;
    }),
    paginatedModel: Ember.computed('model.suspicious_accounts', function () {
      var paginatedModel = this.get('model.suspicious_accounts');
      return paginatedModel;
    }),
    pageNumbers: Ember.computed('totalPages', function () {
      var totalPages = this.get('totalPages');
      var pages = [];

      for (var i = 1; i <= totalPages; i++) {
        pages.push(i);
      }

      return pages;
    }),
    hasPreviousPage: Ember.computed('page', function () {
      var hasPreviousPage = this.page > 1;
      return hasPreviousPage;
    }),
    hasNextPage: Ember.computed('page', 'totalPages', function () {
      var hasNextPage = this.page < this.totalPages;
      return hasNextPage;
    }),
    actions: {
      suspend: function suspend(account) {
        var _this = this;

        return this.client.send('PATCH', "admin/suspend/".concat(account.id)).then(function () {
          var accounts = $.extend(true, [], _this.paginatedModel);
          var index = accounts.findIndex(function (e) {
            return e.id === account.id;
          });
          accounts.splice(index, 1);

          _this.set('model.suspicious_accounts', accounts);
        });
      },
      changePage: function changePage(page) {
        var _this2 = this;

        this.set('page', page);
        return this.client.send('GET', "admin/suspicious-accounts/".concat(page)).then(function (response) {
          _this2.set('model', response);
        });
      }
    }
  });

  _exports.default = _default;
});